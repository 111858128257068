<template>
    <div class="p-1">
        <div class="flex justify-between flex-wrap mb-2">
            <a-button @click="$router.back()">
                Regresar
            </a-button>
        </div>
        <h3 class="text-2xl my-4">
            {{ `${teacher.first_name} ${teacher.last_name}` }}
        </h3>
        <a-card v-if="teacher">
            <div class="flex justify-between">
                <div class="flex">
                    <div class="mr-2 mb-2">
                        <img v-if="teacher.profile_picture" :src="teacher.profile_picture" class="rounded-full w-32 h-32 shadow-md" />
                        <template v-else>
                            <span class="bg-gradient-to-br from-blue-300 to-blue-500 h-32 w-32 rounded-full flex justify-center items-center shadow-md">
                                <span class="text-3xl text-white">{{ teacher.first_name.charAt(0) }}</span>
                            </span>
                        </template>
                        <!-- <img :src="teacher.profile_picture || '@/assets/images/default_image.png'" style="width: 72px; height: 72px; background: slategray; border-radius: 50%;" /> -->
                    </div>
                    <div>
                        <h4 class="text-xl">{{ teacher.is_coordinator ? lang.app.roles.coordinator : lang.app.roles.teacher }}</h4>
                        <p class="text-lg">{{ teacher.id_document }}</p>
                    </div>
                </div>
            </div>
        </a-card>

        <div class="bg-white rounded-2xl mt-3">
            <div class="flex justify-end p-2 items-center">
                <a-select
                    :loading="loadings.periods"
                    class="w-48"
                    :source="periods.map($0 => ({ label: `${$0.year}-${$0.number}`, value: $0.id }))"
                    v-model="period" />
            </div>
            <div class="overflow-x-auto sm:-mx-6 sm:px-6 lg:-mx-8 lg:px-8">
                <div class="align-middle inline-block min-w-full shadow overflow-hidden sm:rounded-lg border-b border-gray-200">
                    <table class="min-w-full">
                        <thead>
                            <tr>
                                <th class="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium uppercase tracking-wider">Hora</th>
                                <th class="px-6 py-3 border-b border-gray-200 bg-gray-50 text-center text-xs leading-4 font-medium uppercase tracking-wider" v-for="(day, k) in days" :key="k">
                                    {{ day.label }}
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(hour, index) in mappedClassHours" :key="hour.value">
                                <td class="px-6 py-4 border-r text-center border-b bg-white border-gray-200">
                                    {{ hour.label.split('/')[0].trim() | h24 }} / {{ hour.label.split('/')[1].trim() | h24 }}
                                </td>
                                <template v-if="hasAnyToShow()">
                                    <td class="px-6 py-4 whitespaence-no-wrap bg-white border-b border-gray-200 text-center mx-auto text-xs" v-for="(day, k) in days" :key="k"
                                        :style="{background: mappedClassroomsBySchedule(hour, day).background, color: mappedClassroomsBySchedule(hour, day).color}">
                                        {{ mappedClassroomsBySchedule(hour, day).title }} <br>
                                        <template v-if="mappedClassroomsBySchedule(hour, day).section">
                                            Sección: {{ mappedClassroomsBySchedule(hour, day).section }}
                                        </template><br>
                                        <template v-if="mappedClassroomsBySchedule(hour, day).classroom">
                                            Aula: {{ mappedClassroomsBySchedule(hour, day).classroom }}
                                        </template>
                                    </td>
                                </template>
                                <template v-else>
                                    <td colspan="5" :rowspan="mappedClassHours.length" class="px-6 py-4 whitespaence-no-wrap bg-white border-b border-gray-200 text-center mx-auto text-sm" v-if="index == 0">
                                        <h3 class="text-2xl">No hay resultados encontrados</h3>
                                    </td>
                                </template>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>

        <a-modal
            title="Un momento!!"
            hide-top-cancel
            cancel-text="Ir al listado de coordinadores"
            @cancel="$router.push({ path: '/coordinators' })"
            :show="isCoordinator && teacher && !teacher.is_coordinator">
            <div class="flex flex-col space-y-4 items-center" v-if="teacher">
                <div class="rounded-full bg-gradient-to-br from-blue-400 to-blue-700 items-center justify-center flex  text-3xl text-white shadow-md" v-if="!teacher.profile_picture">
                    {{ `${teacher.first_name} ${teacher.last_name}` | initials }}
                </div>
                <img v-else :src="teacher.profile_picture" class="rounded-full w-32 h-32 shadow-md">
                <h1 class="text-xl">
                    {{ teacher.first_name }} {{ teacher.last_name }} <b>NO</b> es un coordinador
                </h1>
            </div>
        </a-modal>

        <a-modal
            title="Un momento!!"
            hide-top-cancel
            cancel-text="Ir al listado de profesores"
            @cancel="$router.push({ name: 'teachers.index' })"
            :show="error === 403">
            <div class="flex flex-col space-y-4 items-center">
                <h1 class="text-xl">
                    No tienes autorización para acceder a la información de este profesor
                </h1>
            </div>
        </a-modal>
    </div>
</template>

<script>
import { mapState, mapActions, mapGetters, mapMutations } from 'vuex';
export default {
    props: {
        isCoordinator: { required: false, type: Boolean, default: false }
    },
    data: () => ({
        error: null,
        periods: [],
        period: null,
        teacher: { first_name: '', last_name: '' },
        loadings: {
            periods: false
        }
    }),
    computed: {
        ...mapState({
            teachers: state => state.teachers.pagination.data,
            schdules: state => state.schedules.all,
            loading: state => state.schedules.loading,
            classrooms: state => state.classrooms.all,
            sections: state => state.sections.all,
            classhours: state => state.classhours.all,
            classroomSchedules: state => state.classrooms.schedules,
            sectionSchedules: state => state.sections.schedules,
            teacherSchedules: state => state.teachers.schedules,
        }),
        ...mapGetters({
            lang: 'lang'
        }),
        days() {
            return [
                { label: 'Lunes', value: 1 },
                { label: 'Martes', value: 2 },
                { label: 'Miércoles', value: 3 },
                { label: 'Jueves', value: 4 },
                { label: 'Viernes', value: 5 },
            ]
        },
        mappedClassHours() {
            return this.classhours
                .filter($0 => $0.period_id === this.period)
                .map(it => {
                    const start_hour = this.$options.filters.timePadding(it.start_hour)
                    const start_minute = this.$options.filters.timePadding(it.start_minute)
                    const end_hour = this.$options.filters.timePadding(it.end_hour)
                    const end_minute = this.$options.filters.timePadding(it.end_minute)
                    const first = `${start_hour}:${start_minute}`
                    const second = `${end_hour}:${end_minute}`
                    return {
                        label: `${first} / ${second}`,
                        value: it.id
                    }
                })
                .sort(($0, $1) => $0.label === $1.label ? 0 : $0.label > $1.label ? 1 : -1)
        },
    },
    methods: {
        ...mapActions({
            fetchTeacher: 'teachers/show',
            deleteTeacher: 'teachers/delete',
            fetchClassrooms: 'classrooms/index',
            fetchTeacherSchedule: 'teachers/schedule',
            fetchClasshours: 'classhours/index'
        }),
        ...mapMutations({
            setTeacher: 'teachers/SET_TEACHER',
        }),
        mappedClassroomsBySchedule(hour, day) {
            let found = this.teacherSchedules?.find(it => it.class_hour_id === hour.value && it.day == day.value)

            if (found && found?.course_section?.period_id === this.period) {
                return {
                    title: found.course_section.course.title,
                    section: found.course_section.section.letter_code,
                    classroom: found.classroom.code,
                    background: found.course_section.specialization.color,
                    color: found.course_section.specialization.text_color,
                }
            }
            return {}
        },
        hasAnyToShow() {
            return this.teacherSchedules?.length > 0
        }
    },
    mounted() {

        const { id } = this.$route.params

        this.fetchTeacher({ id })
            .then(teacher => this.teacher = teacher)
            .catch(err => this.error = err.response?.status)

        Promise.all([
            this.$repository.periods.index({ limit: 0 }),
            this.fetchTeacherSchedule({ id, filters: { limit: 0, with: 'courseSection.course,courseSection.specialization,courseSection.section,classroom' } }),
            this.fetchClasshours({ limit: 0 }),
            this.fetchClassrooms({ limit: 0, enabled: true })
        ])
        ._loading(() => this.loadings.periods = !this.loadings.periods)
        .then(([{ data }]) => {
            this.periods = data
            this.period = data.find($0 => $0.enabled)?.id
        })
    }
}
</script>
